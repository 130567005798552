"use client";

import "./globals.css";
import { Benefits } from "@/data/Benefits";
import Jumbotron from "@/components/layouts/Jumbotron";
import { usePathname } from "next/navigation";
import Layouts from "@/components/layouts";
import Core from "@/components/core";
import { Provider } from "react-redux";
import store from "@/store";
import getLastWordFromPath from "@/data/utils/getLastWordPath";
import { GoogleTagManager, GoogleAnalytics } from "@next/third-parties/google";
import dynamic from "next/dynamic";

// const inter = Inter({ subsets: ["latin"] });

const DynamicBenefits = dynamic(() => import("@/components/core/Card"), {
  ssr: false,
});

export default function RootLayout({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) {
  const pathname = usePathname();

  const adsPathname = [
    "/",
    "/alt-ads-2/",
    "/alt-ads-1/",
    "/primary-ads-2/",
    "/alt-ads-3/",
    "/alt-ads-4/",
    "/alt-ads-5/",
    "/alt-ads-6/",
  ];

  const pathnameWithoutSlash = pathname
    ? pathname.substring(1, pathname.length - 1)
    : "";

  const lastWord = getLastWordFromPath(pathnameWithoutSlash) || "";
  const wordsArray = lastWord ? lastWord.split("-") : [];
  const displayText =
    wordsArray.length > 0 ? wordsArray.join(" ") : wordsArray.join(" ");

  return (
    <Provider store={store}>
      <html lang="en">
        <body>
          {pathname !== "/404" && <Layouts.Navbar />}
          <GoogleTagManager gtmId="GTM-WKF57SKN" />
          <GoogleTagManager gtmId="GTM-MDJHJSG3" />
          <GoogleAnalytics gaId="AW-16759129257" />
          <GoogleAnalytics gaId="AW-11363447120" />
          <GoogleAnalytics gaId="AW-337441553" />
          <GoogleAnalytics gaId="G-MKXH5V5NDK" />
          <GoogleAnalytics gaId="G-YPJ4ZQHBB5" />
          {!adsPathname.includes(pathname) && <Jumbotron text={displayText} />}
          {children}
          {pathname === "/" && !adsPathname.includes(pathname) && (
            <>
              <Layouts.Section>
                <Core.Title
                  title="Benefits yang Anda Dapat di MacSpace.id"
                  subtitle="Benefits"
                />
                <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-4">
                  {Benefits.map((item, index) => (
                    <DynamicBenefits
                      key={index}
                      caseType="default"
                      title={item.title}
                      content={item.content}
                      icon={item.icon}
                    />
                  ))}
                </div>
              </Layouts.Section>
              <Layouts.Section variant={"secondary"}>
                <Core.Title title="MacSpace Gallery" subtitle="Gallery" />
                <Layouts.Gallery />
              </Layouts.Section>
            </>
          )}
          {pathname !== "/404" && <Layouts.Footer />}
          <Core.WhatsappPopup />
        </body>
      </html>
    </Provider>
  );
}
